<template>
  <NavBar />
  <main id="home-page">
    <EnrollModal v-if="showEnrollModal" @close="showEnrollModal=false"/>
    <HeroSection @enrollClicked="showEnrollModal=true"/>
    <FeatureSection />
    <PackageSection />
    <ContactSection />


  </main>
</template>


<script>
import NavBar from '@/components/NavBar.vue'
import PackageSection from '@/components/home/PackageSection.vue'
import HeroSection from '@/components/home/HeroSection.vue'
import FeatureSection from '@/components/home/FeatureSection.vue'
import ContactSection from '@/components/home/ContactSection.vue'

import EnrollModal from '@/components/EnrollModal.vue'
export default {
  components: {
    PackageSection, NavBar, HeroSection, FeatureSection, ContactSection,EnrollModal
  },
  data(){
    return{
      showEnrollModal: false
    }
  }
}
</script>
<style lang="scss">
@import '@/styles/home.scss';
</style>
