<template>
    <main id="auth-page">
        <NavBar/>
        <router-view/>
    </main>
</template>

<style lang="scss">
@import '@/styles/auth.scss';
</style>
<script>
import NavBar from '@/components/NavBar.vue';
export default{
    components:{
        NavBar
    }
}
</script>