<template>
  <div id="hero-page">

    <section class="hero-page">

      <div class="hero-content">
        <div class="hero-texts">
          <div class="row default-gap">
            <div class="hero-indicator">
              <img src="@/assets/mascot.png" alt="" rel="preload">
            </div>
            <h2 class="hero-title"><span>Word</span> and <span>Learn</span></h2>
          </div>
          <h1 class="hero-main-title">Transforming Reading and Writing For Young Minds</h1>
          <p class="hero-description">
            Hey there! At WordandLearn, we're all about making reading and writing exciting for every child.
            Our personalized, playful tools turn learning into an exciting experience while being tailored to each child's unique
            needs.
          </p>
        </div>


        <button class="hero-action-btn" id="enroll-btn" @click="$emit('enrollClicked')">

          Enroll Your School Now
          <div class="icon-container">

            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
              <path d="M9 6L15 12L9 18" stroke="#000000" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>

        </button>
      </div>
      <div class="hero-image">
        <div class="background-image">
          <div class="hero-focus-image">
            <!-- <img src="@/assets/remote_learning.png" alt="" /> -->
          </div>
        </div>
      </div>

    </section>
  </div>
</template>
<script>
// import CarouselItem from '@/components/home/CarouselItem.vue'
export default {
  components: {
    // CarouselItem
  },
  data() {
    return {

    }

  },
  computed: {

  },
  methods: {
  }
};
</script>
