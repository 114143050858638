<template>
    <section id="contact-page">
        <div class="contact-details">
            <div class="container">

                <h1 class="contact-us-title">Contact Us</h1>
                <p class="contact-us-text">
                    We'd love to hear from you! Email, call or complete the form below to get in touch with us.
                </p>
                <div class="contact-us-details">
                    <a :href="`mailto:${our_email}`">
                        <p>{{ our_email }}</p>
                    </a>
                    <a :href="`tel:${our_phone}`">

                        <p class="contact-us-phone-number">{{ our_phone }}</p>
                    </a>
                </div>
            </div>



        </div>

        <div class="contact-form">
            <h1 class="contact-form-title">Get in Touch</h1>
            <form @submit.prevent="handleSubmit">

                <div class="contact-form-group">
                    <div class="row">
                        <label for="name">First Name</label>
                        <input type="text" id="fname" name="fname" placeholder="First Name" required v-model="fname">
                        <label for="name">Last Name</label>
                        <input type="text" id="lname" name="lname" placeholder="Last Name" required v-model="lname">
                    </div>
                </div>
                <div class="contact-form-group">
                    <label for="email">Phone Number</label>
                    <MazPhoneNumberInput country-code="KE" v-model="phone_number" />
                </div>
                <div class="contact-form-group">
                    <label for="email">Email</label>
                    <input type="email" id="email" name="email" placeholder="Email Address" v-model="email" required>
                </div>
                <div class="contact-form-group">
                    <label for="message">Message</label>
                    <textarea name="message" id="message" cols="30" rows="10" placeholder="How can we help?" required
                        v-model="message"></textarea>
                </div>
                <button class="contact-form-submit">Submit</button>
            </form>
        </div>
    </section>
</template>

<script>

import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import { firestore } from "@/firebaseInit"
import { collection, addDoc } from "firebase/firestore"
export default {
    components: {
        MazPhoneNumberInput
    },
    data() {
        return {
            fname: '',
            lname: '',
            email: '',
            message: '',
            phone_number: "",
            our_email: "info@wordandlearn.com",
            our_phone: "+254702355935"
        }
    },

    methods: {
        async handleSubmit() {
            var _collection = collection(firestore, "contact-us")
            addDoc(_collection, {
                first_name: this.fname,
                last_name: this.lname,
                email: this.email,
                message: this.message,
                phone_number: this.phone_number
            }).then(() => {
                this.$toast.success("That was a success. We will contact you in a few moments")
            }).catch((error) => {
                console.log(error)
                this.$toast.error("There was an error. Please try again")
            })

        }

    }

}
</script>