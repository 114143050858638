<template>
    <nav>
        <div class="nav-logo">
            <img src="@/assets/logo.png" alt="Logo"/>
        </div>

        <div class="nav-title">
            <div class="row default-gap">
                <a href="#hero-page">
                    Home

                </a>
                <a href="#home-page-packages">

                    Features
                </a>
                <a href="#contact-page">

                    Contact Us
                </a>
               
                <!-- <p>LittleVisions</p> -->
            </div>
        </div>
    
    </nav>
</template>