<template>
    <Teleport to="#app">

        <div class="modal-backdrop fade-in">
            <div class="modal fade-in">
                    <div class="close-btn icon-btn" @click="$emit('close')">
                        <span style="font-size: 25px;">x</span>
                    </div>
                <div class="modal-header">
                    <slot name="header"></slot>
                </div>
                <div class="modal-body">
                    <slot name="body"></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<style>
@import "@/styles/modal.scss";
</style>