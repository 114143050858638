<template>
    <div class="container">
        <div class="center-container">

            <div class="auth-container">
                <!-- <div class="auth-indicators">
                    <div class="auth-indicator">
                        <div class="auth-indicator-circle active">1</div>
                        <div class="auth-indicator-text active">Your Profile</div>
                    </div>
                    <div class="auth-indicator">
                        <div class="auth-indicator-circle">2</div>
                        <div class="auth-indicator-text">Verify Email</div>
                    </div>
                    <div class="auth-indicator">
                        <div class="auth-indicator-circle ">3</div>
                        <div class="auth-indicator-text ">Choose A Plan</div>
                    </div>
                </div> -->
                <div class="auth-title">
                    <p>Welcome Back. Login</p>
                </div>
                <div class="auth-fields">
                    <div class="form-container">
                        <label for="email">Email Address</label>
                        <input type="email" placeholder="Enter email">
                    </div>
                    <div class="form-container">
                        <label for="password1">Password</label>
                        <input type="password" placeholder="Enter Password">
                    </div>

                    <div class="form-container">
                        <p class="paragraph-link">Forgot Your Password? <a>Reset Password</a></p>

                    </div>
                    <div class="form-container">
                        <button>Login</button>
                    </div>
                </div>
                <div class="auth-redirect">
                    <p>Don't Have an Account? <router-link :to="{name: 'register'}">Register</router-link></p>
                </div>
            </div>
        </div>
    </div>
</template>