<template>
    <div class="container">
        <div class="center-container">

            <div class="auth-container">
                <div class="auth-indicators">
                    <div class="auth-indicator">
                        <div class="auth-indicator-circle active">1</div>
                        <div class="auth-indicator-text active">Your Profile</div>
                    </div>
                    <div class="auth-indicator">
                        <div class="auth-indicator-circle">2</div>
                        <div class="auth-indicator-text">Verify Email</div>
                    </div>
                    <div class="auth-indicator">
                        <div class="auth-indicator-circle ">3</div>
                        <div class="auth-indicator-text ">Choose A Plan</div>
                    </div>
                </div>
                <div class="auth-title">
                    <p>Create Your Account</p>
                </div>
                <div class="auth-fields">
                    <div class="form-container">
                        <label for="email">Email Address</label>
                        <input type="email" placeholder="Enter email">
                    </div>
                    <div class="form-container">
                        <label for="password1">Password</label>
                        <input type="password" placeholder="Create Password">
                    </div>
                    <div class="form-container">
                        <input type="password" placeholder="Confirm Password">
                    </div>
                    <div class="form-container">
                        <div class="row default-gap" id="privacy-policy-checkbox">
                            <input type="checkbox" name="" id="">
                            <p>By signing up, you agree to our <a>privacy policy</a> and <a>terms and conditions</a></p>
                        </div>
                    </div>
                    <div class="form-container">
                        <button>Next Step</button>
                    </div>
                </div>
                <div class="auth-redirect">
                    <p>Already Have an Account? <router-link :to="{ name: 'login' }">Login</router-link></p>
                </div>
            </div>
        </div>
    </div>
</template>