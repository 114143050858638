<template>
    <BaseModal>
        <template v-slot:header>
            <h2 class="contact-form-title">Enroll Your School</h2>
            <p class="caption" style="margin-top: 5px;">
                We will contact you on your provided email and phone number to schedule an appointment on enrollment.
            </p>
        </template>
        <template v-slot:body>
            <form @submit.prevent="handleSubmit">
                <div class="contact-form-group">
                    <label for="name">School Name</label>
                    <input type="text" id="fname" name="fname" placeholder="School Name" required v-model="name">

                </div>
                <div class="contact-form-group">
                    <label for="email">School Email</label>
                    <input type="email" id="email" name="email" placeholder="Email Address (Yours or School's)" required
                        v-model="email">
                </div>
                <div class="contact-form-group">
                    <label for="email">Phone Number</label>
                    <MazPhoneNumberInput country-code="KE" v-model="phone_number" />
                </div>
                <button class="contact-form-submit">Submit</button>
            </form>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from './home/BaseModal.vue';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import { firestore } from "@/firebaseInit"
import { collection, addDoc } from "firebase/firestore"
export default {
    components: {
        BaseModal, MazPhoneNumberInput
    },
    data() {
        return {
            email: "",
            phone_number: "",
            name: ""
        }
    },
    methods: {
        async handleSubmit() {

            var _collection = collection(firestore, "school-enrollments")
            addDoc(_collection, {
                email: this.email,
                phone_number: this.phone_number,
                name: this.name
            }).then(() => {
                this.$toast.success("That was a success. We will contact you in a few moments")
            }).catch((error) => {
                console.log(error)
                this.$toast.error("There was an error. Please try again")
            })

        }
    }
}
</script>