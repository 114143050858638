<template>
    <section id="home-page-packages">
        <HomeItem>
            <template v-slot:minimal>
                <div class="home-item">
                    <p class="home-item-subtitle">Ignite Your Child's Reading Ability</p>
                    <h1 class="home-item-title">Read & <br>Imagine</h1>
                    <p class="home-item-description">

                        Spark creativity and comprehension with our story-based learning. Dive into captivating
                        narratives,
                        enhancing reading skills. We empower students to explore and analyze stories, fostering deeper
                        understanding with personalized feedback and interactive activities.
                    </p>

                    <div class="home-item-image">
                        <img src="@/assets/spongebob.png" alt="" rel="preload">
                    </div>

                </div>

            </template>
        </HomeItem>
        <HomeItem>

            <template v-slot:minimal>
                <div class="home-item">
                    <p class="home-item-subtitle">Unlock the Writer Within</p>
                    <h1 class="home-item-title">Write & <br>Tell</h1>
                    <p class="home-item-description">
                        Unlock personalized learning with Word and Learn's innovative approach. Tailored to each child's
                        unique style, our platform seamlessly blends digital and pen-and-paper tasks. We analyze students'
                        compositions for deeper personalization, going beyond standard assessments.
                    </p>

                    <div class="home-item-image">
                        <img src="@/assets/stitch.png" alt="" rel="preload">
                    </div>
                </div>
            </template>
        </HomeItem>
    </section>
</template>

<script>
import HomeItem from '@/components/HomeItem.vue';

export default {
    components: {
        HomeItem
    }
}
</script>